import UpdateClientForm from '../UpdateClientForm/UpdateClientForm'
import './UpdateClient.css'

export default function UpdateClient({user, client, updateClientPage}) {
  return (
    <div className='UpdateClient modal fade' id='updateClientModal' data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`updateClientHeader`} aria-hidden="true">
      <UpdateClientForm user={user} client={client} updateClientPage={updateClientPage} />
    </div>
  )
}
