import NewClientForm from '../NewClientForm/NewClientForm'
import './NewClient.css'

export default function NewClient({user}) {
  return (
    <div className='NewClient modal fade' id='newClientModal' data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`newClientHeader`} aria-hidden="true">
      <NewClientForm user={user} />
    </div>
  )
}
