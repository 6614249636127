import { useEffect, useState } from 'react'
import './NewClientForm.css'
import { provinceOptions } from '../../utilities/services/formOptions';
import { createClient, getClientIDs } from '../../utilities/api/clients'
import { formNotificationStyle2 } from '../../utilities/services/formNotification'
import { useNavigate } from 'react-router-dom'

const defaultFormData = {
  clientID: '',
  companyName: '',
  companyEmail: '',
  phone: '',
  canada: 'y',
  province: '',
  address: '',
  representative: '',
  clientPassword: '',
}


export default function NewClientForm({user}) {

  const passwordLength = 5
  const navigate = useNavigate()
  const [formData, setFormData] = useState(defaultFormData)
  const [existingClientIDs, setExistingClientIDs] = useState([])
  const [validClientID, setValidClientID] = useState(false)

  const handleChange = (evt) => {
    if (evt.target.name === 'canada' && evt.target.value === 'n') {
      const newFormData = {
        ...formData,
        [evt.target.name]: evt.target.value,
        province: '',
      };
      setFormData(newFormData);
    } else {
      const newFormData = {
        ...formData,
        [evt.target.name]: evt.target.value,
      };
      setFormData(newFormData);
    }
    if (evt.target.name === 'clientID') {
      existingClientIDs.includes(parseInt(evt.target.value)) ? setValidClientID(false) : setValidClientID(true)
    }
  }

  const sequentiallyGenerateClientID = async () => {
    try {
      const clientIDs = await getClientIDs()
      setExistingClientIDs(clientIDs)
      // Loop until we get a unique clientID
      let newClientID = 80
      while (clientIDs.includes(newClientID)) {
        // check if newClientID is in clientIDs
        newClientID++
      }
      setValidClientID(true)
      setFormData({
        ...formData,
        clientID: newClientID
      })
    } catch (error) {
      console.log(error)
    }
    
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    // console.log(formData)
    try {
      const client = await createClient({...formData, userID: user._id})
      // console.log(client)
      formNotificationStyle2('clientCreationNotification', 'Client Created Successfully!', '#0A7637', 0.8 )
      setFormData(defaultFormData)
      setTimeout(() => {
        navigate(`/clients/${client._id}`)
        window.location.reload()
      }, 1000)
    } catch (error) {
      formNotificationStyle2('clientCreationNotification', 'Error Creating Client!', '#CA150C', 0.8 )
    }
  }

  useEffect(() => {
    sequentiallyGenerateClientID()
    // eslint-disable-next-line
  },[])

  const disabled = !formData.clientID || !formData.companyName || !formData.companyEmail || !formData.phone || !formData.address || !formData.representative || !formData.clientPassword || formData.clientPassword.length < passwordLength || !validClientID

  
  return (
    <div className='NewClientForm modal-dialog modal-dialog-centered'>
      <div className='modal-content formContainer'>
        <div className='header'>
          <div className='left'>
            <div className='first'>
              <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_225_6075)">
                <rect x="2" y="1.86328" width="48" height="48" rx="10" fill="white"/>
                <rect x="2.5" y="2.36328" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
                </g>
                <path d="M25.6667 28.2718C30.3668 28.2718 34.3333 29.0355 34.3333 31.9822C34.3333 34.93 30.3408 35.6667 25.6667 35.6667C20.9676 35.6667 17 34.9029 17 31.9562C17 29.0084 20.9925 28.2718 25.6667 28.2718ZM25.6667 14C28.8507 14 31.4018 16.5502 31.4018 19.732C31.4018 22.9138 28.8507 25.465 25.6667 25.465C22.4837 25.465 19.9315 22.9138 19.9315 19.732C19.9315 16.5502 22.4837 14 25.6667 14Z" fill="#344054"/>
              </svg>
              &nbsp;&nbsp;
            </div>
            <div className='second'>
              <div className='sec1'>
                Add New Client
              </div>
              <div className='sec2 tertiaryText'>
                Let's create a new client
              </div>
            </div>
          </div>
          <div className='right'>
            <svg data-bs-dismiss="modal" width="25" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#EDEFF2" stroke="#EEEEEE"/>
              <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
        <form id='newClientForm' onSubmit={handleSubmit}>
          <div className='formContent'>
            <div className='mb-3'>
              <label htmlFor='clientID'>Client ID</label>
              <div className='clientIDContainer mb-0 w-25'>
                  <input type='number' className='form-control' id='clientID' onChange={handleChange} name='clientID' min={1} placeholder='Client ID' value={formData.clientID} required />
              </div>
              { formData.clientID === '' &&
              <small className='errorText'>Please Enter Client ID!</small>
              }
              { (formData.clientID !== '' && !validClientID) &&
              <small className='errorText'>Client ID already exists!</small>
              }
            </div>
            <div className='groupContainer'>
              <div className='groupContainerItem'>
                <label htmlFor='companyName'>Client Name</label>
                <input type='text' className='form-control' id='companyName' name='companyName' placeholder='Enter Company Name' onChange={handleChange} value={formData.companyName} required />
              </div>
              <div className='groupContainerItem'>
                <label htmlFor='companyEmail'>Client Email</label>
                <input type='email' className='form-control' id='companyEmail' name='companyEmail' placeholder='Enter email Address' onChange={handleChange} value={formData.companyEmail} required />
              </div>
            </div>
            <div className='groupContainer'>
              <div className='groupContainerItem'>
                <label htmlFor='phone'>Client Phone Number</label>
                <input type='tel' className='form-control' id='phone' name='phone' placeholder='Enter Phone Number' onChange={handleChange} value={formData.phone} required />
              </div>
              <div className='groupContainerItem'>
                <label htmlFor='representative'>Client Representative</label>
                <input type='text' className='form-control' id='representative' name='representative' placeholder='Enter Representative' onChange={handleChange} value={formData.representative} required />
                <small className='text-secondary'>To add multiple representatives, separate names with ","</small>
                
              </div>
            </div>
            <div className='groupContainer'>
              <div className='groupContainerItem'>
                <div>Lead located in Canada?</div>
                <div className='form-check form-check-inline'>
                  <label className='form-check-label' htmlFor="canadaY">Yes</label>
                  <input className='form-check-input' type="checkbox" name="canada" id="canadaY" value="y" checked={formData.canada === 'y'} onChange={handleChange}/>
                </div>
                <div className='form-check form-check-inline'>
                  <label className='form-check-label' htmlFor="canadaN">No</label>
                  <input className='form-check-input' type="checkbox" name="canada" id="canadaN" value="n" checked={formData.canada === 'n'} onChange={handleChange}/>
                </div>
              </div>
              { formData.canada === 'y' &&
              <div className='groupContainerItem'>
                <label htmlFor="province">Province</label>
                <select className='form-select' title='Province' name="province" id="province" value={formData.province} onChange={handleChange}>
                  <option value='' disabled>Select</option>
                  {provinceOptions.map((provinceItem, index) => (
                    <option key={index} value={provinceItem.value}>{provinceItem.name}</option>
                  ))}
                </select>
              </div>
            }
            </div>
            <div className='groupContainer'>
              <div className='groupContainerItem'>
                <label htmlFor='address'>Client Address</label>
                <input type='text' className='form-control' id='address' name='address' placeholder={`${formData.canada==='y'? "Enter Street Address": "Enter Full Address"}`} onChange={handleChange} value={formData.address} required />
              </div>
              <div className='groupContainerItem'>
                <label htmlFor="clientPassword">Client Password</label>
                <input type='text' className='form-control' id='clientPassword' name='clientPassword' placeholder='Client Password' onChange={handleChange} value={formData.clientPassword} required />
                { formData.clientPassword === '' &&
                <small className='text-secondary'>Minimum 5 characters</small>
                }
                { (formData.clientPassword !== '' && formData.clientPassword.length < passwordLength) &&
                <small className='errorText'>Password must be at least 5 characters!</small>
                }
              </div>
            </div>
            <div className='form-group mb-3'>
              
            </div>
            <div className='form-group mb-3'>
              
            </div>
          </div>
          {/* <div className='d-flex justify-content-center formSubmitButtonContainer'>
            <button className="btn btn-primary my-2 my-sm-0" type="submit" disabled={disabled}>Add Client</button>
          </div>
          <div><p id='clientCreationNotification'className='clientCreationNotification text-center'></p></div> */}
        </form>
        <div className='d-flex justify-content-center formButtonContainer'>
          <button data-bs-dismiss="modal" className='cancel'>Cancel</button>
          <button className="primary submit" form='newClientForm' type="submit" disabled={disabled}>Add Client</button>
        </div>
        <p id='clientCreationNotification'className='clientCreationNotification text-center'></p>
      </div>
    </div>
  )
}
