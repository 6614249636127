import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter} from '../../utilities/services/capitalizeFirstLetter'
import './ClientListItem.css'

export default function ClientListItem({client}) {
    const navigate = useNavigate()

    const handleClick = () => {
        // console.log(client._id)
        navigate(`/clients/${client._id}`)
    }
  return (
    <tr className='ClientListItem' onClick={handleClick}>
        <td className='companyName'>{capitalizeFirstLetter(client.companyName)}</td>
        <td>{client.clientID}</td>
        <td>{client.companyEmail}</td>
        <td>{client.companyPhoneNumber}</td>
        <td>{client.province? client.province:'---'}</td>
        <td className='creationDate'><span>{client.createdAt.split('T')[0]}</span></td>
    </tr>
  )
}
